@import url("~leaflet/dist/leaflet.css");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card-container2.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.css-13cymwt-control {
  border-radius: 10px !important;
}

.btn-primary {
  background-color: #e3007a !important;
  border-color: #e3007a !important;
  border-radius: 10px;
}

.btn-submit {
  background-color: #bcb3b9 !important;
  border-color: #a49fa2 !important;
  border-radius: 10px;
}

.login-header {
  color: #788491;
  text-align: center;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #ba0264 !important;
  border-color: #ba0264 !important;
}

iframe
{
    display: none;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.main-header {
  margin-top: 0px !important;
  max-width: 100%;
  margin: 0;
  padding: 0 !important;
}

.for-tile {
  display: none;
}

.main-container {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
}

.for-height {
  height: 100%;
  width: 100%;
}

.card {
  border-radius: 10px ;
}

.form-control {
  border-radius: 10px;
}

.reachad-logo {
  max-height: 40px;
  margin-right: 1rem;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  background-color: inherit;
  border: none;
}
.info-item {
  color: #212529;
  text-decoration: underline;
}

.info-item:hover {
  color: #676a71;
}

.table-body {
  width: 100%;
}

.table-init {
  padding: 1rem 0px;
  text-align: center;
}

.bm-item a {
  color: #d1d1d1;
}

.bm-item:hover {
  color: white;
  text-decoration: underline;
}

.bm-item:hover a {
  color: white;
  text-decoration: underline;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 82px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #343a40;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.features-container {
  position: absolute;
  z-index: 999;
  right: 10px;
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  height: 100%;
  max-height: 780px;
}

#active-feature{
  overflow: scroll;
}

.features {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.877);
  width: 300px;
  padding: 15px;
  margin: 25px;
  cursor: pointer;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.feature {
  cursor: pointer;
  margin-left: 10px;


}


.tooltip-feature {
  position: relative;
  display: inline-block;
}

.tooltip-feature .tooltiptext-feature {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 300%;
}

.tooltip-feature:hover .tooltiptext-feature {
  visibility: visible;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-label {
  margin-bottom: 0;
}

.legend-container-ppp {
  position: absolute;
  z-index: 999;
  right: 30px;
  width: 140px;
  display: none;
  justify-content: space-around;
  flex-direction: column;
  bottom: 30px;;
}

.filter-block {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.877);
  padding: 8px;
  margin-top: 15px;
}

.filter-block-legend {
  padding: 12px;
}

.legend-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend-color-block {
  border: 2px solid black;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.btn-primary-legend {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}

.btn-primary-legend:hover, .btn-primary-legend:active, .btn-primary-legend:visited {
  background-color: #026ee1;
  border-color: #0172ea;
  color: #fff;
}

.hr-icon {
  width: 3em;
  height: 3em;
  margin-right: 10%;
}

.hr-icon-average {
  width: 5em;
  height: 5em;
  margin-right: 5%;
}

.hr-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: #fff
}

.block-info {
  display: flex;
  width: 24.5%;
  justify-content: center;
}

.info-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-flex {
  display: flex;
  justify-content: space-between;
}

.bm-overlay {
  width: 0px !important;
}

.bm-menu-wrap {
  position:relative !important;
  width: 350px;
}

.new-card {
  margin: 0; 
  border: none; 
  box-shadow: none;
}

.col-12-new {
  padding-right: 0; 
  padding-left: 0;
}

.heatmap-block {
  display: flex;
  justify-content: space-between;
}

.heatmap-container {
  padding: 20px;
  height: 550px;
  background-color: #ffffff;
  width: 49%;
}

.average-container {
  padding: 20px;
  height: 550px;
  background-color: #ffffff;
  width: 24%;
}

.heatmap-title {
  text-align: center;
  font-size: 16px;
  padding-bottom: 15px;
  color: #788491;
}

.heatmap-item {
  background-color: #edecec;
  height: 465px;
}

.pp-item {
  display: flex;
  font-size: 13px;
}

.app-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  background-color: #343a40; /* Black background color */
  width: 80px; /* Initially, the sidebar is hidden */
  height: 1650px; /* 100% viewport height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  color: #fff; /* Text color for the sidebar */
  position: absolute; /* Fixed position so it stays on the screen */
  top: 56px;
  left: 0;
  transition: 0.3s; /* Add transition for smooth open/close effect */
}

.sidebar.open {
  width: 250px; /* Width when the sidebar is open */
}

.burger-menu,
.close-button {
  font-size: 35px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 2; /* Ensure the icons are on top of the sidebar */
}

.close-button {
  display: none; /* Initially, the close button is hidden */
}

.sidebar.open .burger-menu {
  display: none; /* Hide burger menu icon when the sidebar is open */
}

.sidebar.open .close-button {
  display: block; /* Show the close button when the sidebar is open */
}

.sidebar.open + .content {
  margin-left: 250px;
}

.sidebar + .content {
  margin-left: 80px;
}

/* Add transitions for smooth icon changes */
.sidebar .burger-menu,
.sidebar .close-button {
  transition: 0.3s;
}

/* Additional styling for the burger menu and close button icons */
.burger-menu:hover,
.close-button:hover,
.menu-item-sidebar:hover {
  color: #948f8f; /* Change color on hover if desired */
}

.content {
  flex: 1; /* Allow content to grow and fill available space */
}

.menu-item-sidebar {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color .2s;
  background-color: inherit;
  border: none;
  text-align: left;
  padding-left: 10px;
}

.sidebar-content {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.info-content {
  padding-left: 5px;
}

.table-container {
  overflow-x: auto;
}

.header-top {
  margin-top: 10px;
}

.footer-item {
  margin-bottom: 10px;
}

.btn-radius {
  border-radius: 10px;
}

.border-fix {
  border-right: 2px solid black !important; /* Fix border style for the Edit button */
}

.border-input {
  border-right: 1px solid black !important; /* Fix border style for the input */
}

.border-header {
  border-bottom: 1px solid #fff !important; /* Fix border style for the header */
}

.table-state {
  width: 100%;
}

.tr-header {
  border-bottom: 1px solid black;
}

.tr-row {
  border-bottom: 1px solid #ece6e6;
}